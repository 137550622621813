$(document).ready(function () {
	$('.popup-with-zoom-anim').magnificPopup({
		type: 'inline',

		fixedContentPos: true,
		fixedBgPos: true,

		overflowY: 'auto',

		closeBtnInside: true,
		preloader: false,

		midClick: true,
		removalDelay: 300,
		mainClass: 'my-mfp-zoom-in'
	});
});

jQuery('.zoom-gallery').each(function () { // the containers for all your galleries
	jQuery(this).magnificPopup({
		delegate: 'a',
		type: 'image',

		fixedContentPos: true,
		fixedBgPos: true,

		closeOnContentClick: false,
		closeBtnInside: false,
		mainClass: 'mfp-with-zoom mfp-img-mobile',
		image: {
			verticalFit: true
		},
		gallery: {
			enabled: true
		},
		zoom: {
			enabled: true,
			duration: 300, // don't foget to change the duration also in CSS
			opener: function (element) {
				return element.find('img');
			}
		}
	});
});

// $(document).ready(function() {
// 	$('.zoom-gallery').magnificPopup({
// 		delegate: 'a',
// 		type: 'image',

// 		fixedContentPos: true,
// 		fixedBgPos: true,

// 		closeOnContentClick: false,
// 		closeBtnInside: false,
// 		mainClass: 'mfp-with-zoom mfp-img-mobile',
// 		image: {
// 			verticalFit: true
// 		},
// 		gallery: {
// 			enabled: true
// 		},
// 		zoom: {
// 			enabled: true,
// 			duration: 300, // don't foget to change the duration also in CSS
// 			opener: function(element) {
// 				return element.find('img');
// 			}
// 		}

// 	});
// });

let alertPopup = function () {
	$.magnificPopup.open({
		items: {
			src: '#disclaimer-popup'
		},

		type: 'inline',

		fixedContentPos: true,
		fixedBgPos: true,

		overflowY: 'auto',

		closeBtnInside: false,
		preloader: false,
		closeOnBgClick: false,
		enableEscapeKey: false,

		midClick: false,
		removalDelay: 300,
		mainClass: 'my-mfp-slide-bottom'
	});

	let button = document.querySelector('.buttons-wrapper>button')
	if (button) {
		button.addEventListener('click', function () {
			$.magnificPopup.close();
		})
	}

	// let newCookie = function() {
	//   $.cookie("popup", "", {
	//     expires: 1,
	//     path: '/'
	//   });
	// }

	// $(".mfp-close").click(function () {
	//   newCookie()
	// });

	// $(".mfp-wrap").click(function () {
	//   newCookie()
	// });
}

$(document).ready(function () {
	// if ($.cookie("popup") == null) {
	if (document.querySelector('#disclaimer-popup')) {
		alertPopup()
	}
	// }
});

// Video Popup

$(document).ready(function () {
	$('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
		type: 'iframe',
		iframe: {
			patterns: {
				vimeo: {
					index: 'vimeo.com/',
					id: '/',
					src: '//player.vimeo.com/video/%id%'
				},
			}
		},
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,

		fixedContentPos: false
	});
});