let vimeoPlay = function () {
  let vimeos = document.querySelectorAll('.art-slider iframe.hero__video')
  vimeos.forEach(function (vimeo) {
    let player = new Vimeo.Player(vimeo);
    player.play();
  })
}

document.addEventListener('click', function (e) {
  let target = e.target
  if (target.closest('.mfp-close') || target.closest('.mfp-container') || target.closest('.mfp-iframe-holder')) {
    vimeoPlay()
  }
})