(function () {

  //for fitness -----------------------------------------------------

  let fitnessActive = function () {

    let items = document.querySelectorAll('.other-amenities__nav-item')

    items.forEach(function (item) {
      if (item.getAttribute('data-item-name') == 'fitness' || item.getAttribute('data-item-name') == 'item-4') {
        $(item).first().trigger("click");
      }
    })
    $("a[rel='m_PageScroll2id']").mPageScroll2id({
      offset: 100,
    });
    $.mPageScroll2id("scrollTo", "#other-amenities");
  }

  // ---------------------------------------------------------------------------

  const $otherAmenities = $(".other-amenities");

  if (!$otherAmenities.length) return;

  $otherAmenities
    .find(".other-amenities__nav")
    .on("click", ".other-amenities__nav-item", function () {
      $otherAmenities.find("[data-item-name].active").removeClass("active");

      const name = $(this).data("item-name");

      $otherAmenities.find(`[data-item-name="${name}"]`).addClass("active");
    });


  if (document.querySelector('.other-amenities') && location.hash) {
    fitnessActive()
  } else {
    $(".other-amenities__nav-item").first().trigger("click");
  }
})();