(function() {
  const $amenities = $(".amenities");

  if (!$amenities.length) return;

  let viewport = document.querySelector(".amenities__viewport");

  if (!viewport) return;

  let content = viewport.querySelector(".amenities__list");

  let activeItem = null;

  const scrollBoosterParams = {
    viewport, // this parameter is required
    content, // scrollable element
    mode: "x", // scroll only in horizontal dimension
    onClick: (data, e) => {
      if (Math.abs(data.dragOffsetPosition.x) > 5) {
        e.preventDefault();
      }
    },
    shouldScroll: () => window.innerWidth < 992 && !activeItem,
    onUpdate: data => {
      // your scroll logic goes here
      content.style.transform = `translateX(${-data.position.x}px)`;
    }
  };

  let sb;

  function initSb() {
    sb = new ScrollBooster(scrollBoosterParams);
  }

  if (document.readyState == "complete") {
    initSb();
  } else {
    $(window).on("load", initSb);
  }

  let timeout;

  function listClickHandler() {
    if (!activeItem) return;
    const href = activeItem.href;

    activeItem.click();
    // location.href = href
    // $(activeItem).trigger("click");
  }

  function setActiveItem(item) {
    activeItem = item;
    const amenitiesTitle = activeItem.dataset.amenities;

    $(".amenities__full-item.active").removeClass("active");
    $(".amenities__descr.active").removeClass("active");
    $(`.amenities__full-item[data-amenities="${amenitiesTitle}"]`).addClass(
      "active"
    );
    $(`.amenities__descr[data-amenities="${amenitiesTitle}"]`).addClass(
      "active"
    );
  }

  function removeActiveItem() {
    activeItem = null;
  }

  function enterItem() {
    if (activeItem || window.innerWidth < 992) return;
    clearTimeout(timeout);
    setActiveItem(this);
    $amenities.removeClass("end-interaction").addClass("start-interaction");
  }

  function leaveItem() {
    if (!activeItem) return;
    $amenities.addClass("end-interaction");
    clearTimeout(timeout);
    removeActiveItem();
    removeDescr();
    timeout = setTimeout(() => {
      $amenities.removeClass("start-interaction end-interaction");
    }, 2000);
  }

  $amenities.on("mouseenter", ".amenities__item", enterItem);
  $amenities.on("mouseleave", ".amenities__item", leaveItem);
  $amenities.on("click", ".amenities__viewport", listClickHandler);
})();

let removeDescr = function() {
  let activeItem = document.querySelector('.amenities__descr.active')
  activeItem.classList.remove('active')
}