(function() {
  const $wrapper = $(".suites-and-rooms");

  if (!$wrapper.length) return;

  const $hiddenInput = $wrapper.find(".book-room__form input[name=type]");
  let currentActive;

  function removeActive() {
    $(".suites-and-rooms__nav-item.active").removeClass("active");
    $(".suites-and-rooms__item.active").removeClass("active");
    $(".suites-and-rooms__img-block img.active").removeClass("active");
  }

  function onItemClick() {
    removeActive();

    currentActive = $(this).data("suites-and-rooms");

    $hiddenInput.val(currentActive);

    $(this).addClass("active");
    $(
      `.suites-and-rooms__img-block img[data-suites-and-rooms="${currentActive}"]`
    ).addClass("active");

    $(
      `.suites-and-rooms__item[data-suites-and-rooms="${currentActive}"]`
    ).addClass("active");
  }

  $(".suites-and-rooms__nav").on("click", ".suites-and-rooms__nav-item", onItemClick);

  $(".suites-and-rooms__nav-item")
    .first()
    .trigger("click");
})();
