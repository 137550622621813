(function() {
  const $scrollboosters = $("[data-scrollbooster]");

  $scrollboosters.each(function() {
    const $this = $(this);
    const mode = $this.data("scrollbooster-mode") || "x";
    const until = $this.data("scrollbooster-until");
    const content = $this.children()[0]

    const scrollBoosterParams = {
      viewport: $this[0], // this parameter is required
      content, // scrollable element
      mode, // scroll only in horizontal dimension
      onClick: (data, e) => {
        if (Math.abs(data.dragOffsetPosition.x) > 5) {
          e.preventDefault();
        }
      },
      shouldScroll: () => (until ? window.innerWidth < until : true),
      onUpdate: data => {
        content.style.transform = `translateX(${-data.position.x}px)`;
      }
    };

    new ScrollBooster(scrollBoosterParams);
  });
})();
