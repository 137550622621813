let gallerys = document.querySelectorAll('.gallery')

let setGallaryHeight = function(gallery) {
  let galleryGrid = gallery.querySelector('.first-row')
  let galleryItems = gallery.querySelectorAll('.first-row__item')

  galleryGrid.style.height = ``
  let galleryTop = $(galleryGrid).offset().top;
  let lastItem = galleryItems[galleryItems.length - 1]
  let lastItemTop = $(lastItem).offset().top;
  let lastItemHeight = lastItem.offsetHeight
  let lastItemBot = lastItemTop + lastItemHeight

  let lastItem2 = galleryItems[galleryItems.length - 2]
  let lastItem2Top = $(lastItem2).offset().top;
  let lastItem2Height = lastItem2.offsetHeight
  let lastItem2Bot = lastItem2Top + lastItem2Height

  let galleryBot = lastItem2Bot

  if (lastItemBot >= lastItem2Bot) {
    galleryBot = lastItemBot
  } else {
    galleryBot = lastItem2Bot
  }

  let galleryHeight = galleryBot - galleryTop
  if (window.innerWidth > 992) {
    galleryGrid.style.height = `${galleryHeight}px`
  }

  console.log(galleryGrid.style.height)
}

if (gallerys.length > 0) {
  gallerys.forEach(function(gallery) {
    setGallaryHeight(gallery)
  })

  window.addEventListener('resize', function() {
    gallerys.forEach(function(gallery) {
      setGallaryHeight(gallery)
    })
  })
}