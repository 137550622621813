(function () {

    if (document.getElementsByClassName("appointment__selecter")[0]) {
        document.getElementsByClassName("appointment__selecter")[0].onclick = function (event) {
            let target = event.target;
    
            if (target.tagName.toLowerCase() == 'li') {
                filter(target);
            } else {
                return;
            }
        };
    
        function filter(e) {
            let removeClass = document.getElementsByClassName("appointment__selecter--active");
            if (removeClass[0] == e || !removeClass[0]) {
                return
            } else {
                removeClass[0].classList.remove('appointment__selecter--active');
            }
            e.classList.add('appointment__selecter--active');
            let rooms = document.getElementsByClassName("appointment__room");
            for (let i = 0; i < rooms.length; i++) {
                if (rooms[i].getAttribute('data').toLowerCase() == e.getAttribute('data').toLowerCase()) {
                    rooms[i].className = "appointment__room appointment__room--active"
                } else {
                    rooms[i].className = "appointment__room"
                }
            }
            getHeight();
        }
    }
})();

function getHeight() {
    let getHeight = document.getElementsByClassName("appointment__room--active")[0].offsetHeight;
    let setGeight = document.getElementsByClassName("appointment__right--room");
    console.log(getHeight);
    setGeight[0].setAttribute("style",`height:${getHeight}px`);
};

if (document.getElementsByClassName("appointment__room--active")[0]){
    getHeight();
}