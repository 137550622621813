(function() {
  $(".link").attr("data-aos", '');

  $("[data-aos='custom-slide-up']").each(function() {
    const $this = $(this);
    const text = $this.html();
    $this.html(`<div class="hidden">${text}</div>`);
  });

  document.addEventListener("aos:in", ({ detail }) => {
    const $this = $(detail);

    if ($this.data("aos") === "stagger") {
      const delay = $this.data("aos-delay") || 0;
      const speed = $this.data("aos-duration") || 1000;
      const interval = $this.data("aos-interval") || 500;

      TweenMax.staggerFrom(
        $this.children().toArray(),
        speed / 1000,
        { delay: delay / 1000, alpha: 0, y: 50, ease: Power1.easeOut },
        interval / 1000
      );
    }
  });

  AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    // startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
    initClassName: "aos-init", // class applied after initialization
    animatedClassName: "aos-animate", // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 100, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: "ease", // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: "top-bottom" // defines which position of the element regarding to window should trigger the animation
  });

  // ANIMATE LETTERS
  $(".animate-letters").each(function() {
    const $this = $(this);
    const delay = ($this.data("letters-delay") || 0) / 1000;

    const text = $this.text().trim();
    $this.text("").css("visibility", "visible");

    const words = text.split(" ");
    const wordsElems = words.map(word => {
      return $(`<span style="display: inline-block" data-word="${word}">`);
    });

    wordsElems.forEach(function(ele, idx) {
      const $word = $(ele);
      const word = $word.data("word");
      const letters = word.split("");
      const lettersElements = letters.map(l =>
        $('<span data-letter style="display: inline-block">' + l + "</span>")
      );

      $word.append(...lettersElements);

      if (idx !== 0) {
        $this.append($('<span style="display: inline-block">&nbsp;</span>'));
      }
      $this.append(ele);
    });

    // const shuffled = _.shuffle($this.find("[data-letter]").toArray()); -- random letters
    const linear = $this.find("[data-letter]").toArray();
    TweenMax.staggerFrom(
      linear,
      0.3,
      { delay, alpha: 0, y: 15, ease: Power1.easeOut },
      0.15
    );
  });

  // function calcHeight() {
  //   $(".calc-height").each(function() {
  //     const $this = $(this);
  //     $this.height($this.height("auto").height());
  //   });
  // }

  // function calcWidth() {
  //   $(".calc-width").each(function() {
  //     const $this = $(this);
  //     $this.width($this.width("auto").width());
  //   });
  // }

  // calcHeight();
  // calcWidth();

  // $(window).on("resize", function() {
  //   calcHeight();
  // calcWidth();
  // });
})();
