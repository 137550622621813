(function() {
  const $toggler = $(".room-type-toggler");

  if (!$toggler.length) return;

  $toggler.on("click", "[data-room-type]", function() {
    const type = $(this).data("room-type");

    $("[data-room-type].active").removeClass("active");
    $(`[data-room-type="${type}"]`).addClass("active");
  });

  $toggler
    .find("[data-room-type]")
    .first()
    .click();
})();
