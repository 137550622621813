// parallax-----------------------------------------
const rellaxOptions = {
    center: true,
    speed: 2
};

let rellax = new Rellax(".rellax", rellaxOptions);
let isParallax = true;
if ($(".rellax").length) {

    function setParallax() {
        if (window.innerWidth < 768 && isParallax) {
            rellax.destroy();
            isParallax = false;
        } else if (window.innerWidth >= 768 && !isParallax) {
            rellax = new Rellax(".rellax", rellaxOptions);
            isParallax = true;
        }
    }

    setParallax();
    $(window).on("resize", debounce(setParallax, 50));
}

function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}
// end parallax---------------------------------------------

let refresh = function () {
    AOS.refresh();
    if (window.innerWidth >= 768) {
        rellax.refresh();
    }
}

let forDropMenu = function (button) {

    let menuName = document.querySelector('.filter__name')
    if (menuName) {
        menuName.querySelector('h3').textContent = button.querySelector('h3').textContent;
    }

}

let hideCategory = function (buttonCategory, content, hide) {

    let items = document.querySelectorAll(content)
    items.forEach(function (item) {
        let itemCategory = item.getAttribute('data')
        if (itemCategory) {
            itemCategory.toLowerCase()
        }
        if (buttonCategory === itemCategory) {
            item.classList.remove(hide)
        } else {
            item.classList.add(hide)
        }
    })

}

let toggleClass = function (button) {
    let delClass = document.querySelector('.filter-item--active')
    if (delClass) {
        delClass.classList.remove('filter-item--active')
    }
    button.classList.add('filter-item--active')
}

let hideOnLoad = function (content, method = '.mPS2id-clicked', hide = 'notDisplay') {

    let buttonCategory = document.querySelector(method)
    if (buttonCategory) {

        if (method == '.mPS2id-clicked') {
            buttonCategory = buttonCategory.parentElement.getAttribute('data')
        } else {
            buttonCategory = buttonCategory.getAttribute('data')
        }

        if (buttonCategory) {
            buttonCategory.toLowerCase()
        } else {
            return
        }
    }

    hideCategory(buttonCategory, content, hide)

    refresh()
}

let filter = function (target, content, method, hide = 'notDisplay') {

    let buttons = document.querySelectorAll(target)
    buttons.forEach(function (button) {
        button.addEventListener('click', function () {
            if (method) {
                toggleClass(button)
            }

            let buttonCategory = button.getAttribute('data')
            if (buttonCategory) {
                buttonCategory.toLowerCase()
            } else {
                return
            }

            forDropMenu(button);

            hideCategory(buttonCategory, content, hide)

            refresh()
        })
    })

};

//for another active item-----------------------------------------------------

let clickGetAnchor = () => {
    document.addEventListener('click', (e) => {
        let target = e.target
        let hasDataAttribute = false;
        let dataAttributeValue = null;

        while (target !== document) {
            if (target.nodeType === Node.ELEMENT_NODE) {
                if (target.getAttribute('data')) {
                    hasDataAttribute = true;
                    dataAttributeValue = target.getAttribute('data');
                    break;
                }
            }
            target = target.parentNode;
        }

        if (hasDataAttribute) {
            window.location.hash = dataAttributeValue;
        }
    })
}
clickGetAnchor();

let customAnchor = function (filterItem, blockAnchor) {
    $(window).scrollTop(0)

    document.querySelector('.mPS2id-clicked').classList.remove('mPS2id-clicked')
    let items = document.querySelectorAll(filterItem)

    items.forEach(function (item) {
        let dataName = item.getAttribute('data')
        if (`#${dataName}` == location.hash) {
            item.firstElementChild.classList.add('mPS2id-clicked')
        }
    })
    $("a[rel='m_PageScroll2id']").mPageScroll2id({
        offset: 300,
    });
    $.mPageScroll2id("scrollTo", blockAnchor);
}

if (document.querySelector('.news--filtered') && location.hash) {
    customAnchor('.filter-item', "#special-events")
}

if (document.querySelector('.bistro--filtered') && location.hash) {
    customAnchor('.filter-item--bistro', "#bistro")
}

// ---------------------------------------------------------------------------

// special-offers
filter('.filter-item', '.news-item')
// bistro
filter('.filter-item--bistro', '.bistro__category')
// dine
filter('.filter-item', '.dine__item', 'yes', 'hideItem')

// bistro
if (document.querySelector('.bistro--filtered .bistro__category')) {
    hideOnLoad('.bistro--filtered .bistro__category')
}
// special-offers
if (document.querySelector('.news-item')) {
    hideOnLoad('.news-item')
}
// dine
if (document.querySelector('.dine__item')) {
    hideOnLoad('.dine__item', '.filter-item--active', 'hideItem')
}

// ----------------tabs-----------------------------------------------

let tabs = document.querySelectorAll('.block-tabs__tabs>button')
let photos = document.querySelectorAll('.block-tabs__photo-item')

if (tabs.length > 0) {
    tabs[0].classList.add('active')
    photos[0].classList.add('active')
    tabs.forEach(function (tab) {
        tab.addEventListener('click', function () {
            if (tab.classList.contains('active')) {
                return
            } else {
                let activeTab = document.querySelector('.block-tabs__tabs>button.active')
                let activePhoto = document.querySelector('.block-tabs__photo-item.active')
                activeTab.classList.remove('active')
                activePhoto.classList.remove('active')
                tab.classList.add('active')
                let buttonData = tab.getAttribute('data-photo')
                photos.forEach(function (photo) {
                    let photoData = photo.getAttribute('data-photo')
                    if (buttonData == photoData) {
                        photo.classList.add('active')
                    }
                })
            }
            refresh()
        })
    })
}