$('.fullwidth-slick').slick({
  dots: false,
  infinite: true,
  speed: 1000,
  fade: true,
  cssEase: 'linear',
  autoplay: true,
  autoplaySpeed: 4000,
  prevArrow: "<div class='slick-arrow prev-arrow'><svg><use xlink:href='/wp-content/themes/taaTheme/svg-sprite/sprite.svg#square_arrow'></use></svg></div>",
  nextArrow: "<div class='slick-arrow next-arrow'><svg><use xlink:href='/wp-content/themes/taaTheme/svg-sprite/sprite.svg#square_arrow'></use></svg></div>",
});

$('.art-slider').slick({
  dots: true,
  infinite: true,
  speed: 1000,
  fade: true,
  cssEase: 'linear',
  autoplay: false,
  autoplaySpeed: 4000,
  prevArrow: "<div class='slick-arrow prev-arrow'><svg><use xlink:href='/wp-content/themes/taaTheme/svg-sprite/sprite.svg#icon-arrow'></use></svg>prev</div>",
  nextArrow: "<div class='slick-arrow next-arrow'>next<svg><use xlink:href='/wp-content/themes/taaTheme/svg-sprite/sprite.svg#icon-arrow'></use></svg></div>",
});

$('.block-tabs__slider').slick({
  dots: false,
  infinite: true,
  speed: 1000,
  fade: true,
  cssEase: 'linear',
  autoplay: false,
  autoplaySpeed: 4000,
  prevArrow: "<div class='slick-arrow prev-arrow'><svg><use xlink:href='/wp-content/themes/taaTheme/svg-sprite/sprite.svg#icon-arrow'></use></svg>prev</div>",
  nextArrow: "<div class='slick-arrow next-arrow'>next<svg><use xlink:href='/wp-content/themes/taaTheme/svg-sprite/sprite.svg#icon-arrow'></use></svg></div>",
});

$('.image-carousel__slider').slick({
  dots: false,
  infinite: true,
  speed: 1000,
  fade: true,
  cssEase: 'linear',
  autoplay: true,
  autoplaySpeed: 2000,
  prevArrow: "<div class='slick-arrow prev-arrow'><svg><use xlink:href='/wp-content/themes/taaTheme/svg-sprite/sprite.svg#icon-arrow'></use></svg>prev</div>",
  nextArrow: "<div class='slick-arrow next-arrow'>next<svg><use xlink:href='/wp-content/themes/taaTheme/svg-sprite/sprite.svg#icon-arrow'></use></svg></div>",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
      }
    }
  ]
});