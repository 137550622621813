(function () {
  $(".custom-datepicker").each(function () {
    const $this = $(this);
    const $wrapper = $this.parent();
    let isOpened = false;

    $this.datepicker({
      autoClose: true,
      language: "en",
      navTitles: {
        days: "M"
      },
      minDate: new Date(),
      onShow: function (inst, animationCompleted) {
        $wrapper.addClass("active");

        if (animationCompleted) {
          isOpened = true;
        }
      },
      onHide: function (inst, animationCompleted) {
        $wrapper.removeClass("active");

        if (animationCompleted) {
          isOpened = false;
        }
      }
    });

    $this.on("click", function () {
      if (isOpened) {
        $this.data("datepicker").hide();
      }
    });
  });
})();

var $start1 = $('.custom-datepicker--start'),
  $end1 = $('.custom-datepicker--end');

var $start2 = $('.book-room__datepicker--start'),
  $end2 = $('.book-room__datepicker--end');

var $start3 = $('.book-room__datepicker-2--start'),
  $end3 = $('.book-room__datepicker-2--end');

let StartEnd = function ($start, $end) {
  $start.datepicker({
    onSelect: function (fd, date) {
      $end.data('datepicker')
        .update('minDate', date);

      $end.focus();
    }
  })

  $end.datepicker({
    onSelect: function (fd, date) {
      $start.data('datepicker')
        .update('maxDate', date)
    }
  })
}

StartEnd($start1, $end1)
StartEnd($start2, $end2)
StartEnd($start3, $end3)