let headerDark = document.querySelector('.page-header')
let logo = document.querySelector('.page-header__logo')
let hero = document.querySelector('.hero')
let heroHeight

if (hero) {
    heroHeight = hero.offsetHeight
} else {
    heroHeight = 9999
}

function consoleBG() {
    if ($(window).scrollTop() < heroHeight - 150) {
        headerDark.classList.remove('page-header--scroll')
        logo.classList.add('small-logo')
    } else {
        headerDark.classList.add('page-header--scroll')
        logo.classList.remove('small-logo')
    }
}
consoleBG()

$(window).scroll(function () {
    consoleBG()
});


function posFixed(filter, type, content) {
    let filters = document.querySelectorAll(filter)
    if (filters.length > 0) {
        filters.forEach((filterPosition) => {
            let wrapper = filterPosition.closest(type);
            let elemPosition = $(wrapper).offset().top;
            // let elemPosition = $(type).offset().top

            let headerHeight = $('.page-header').height()

            let blockHeight = $(content).height()
            let filterHeight = $('.filter').height()

            if (screen.width > 1365) {
                if ($(window).scrollTop() < elemPosition - headerHeight + 100) {
                    filterPosition.classList.remove('fixed')
                    filterPosition.classList.remove('fixed-top')
                    filterPosition.classList.remove('bottom')
                } else if ($(window).scrollTop() > elemPosition + blockHeight - filterHeight - 60) {
                    filterPosition.classList.remove('fixed-top')
                    filterPosition.classList.remove('fixed')
                    filterPosition.classList.add('bottom')
                } else if ($(window).scrollTop() > elemPosition) {
                    filterPosition.classList.remove('bottom')
                    filterPosition.classList.remove('fixed-top')
                    filterPosition.classList.add('fixed')
                }
            } else {
                if ($(window).scrollTop() < elemPosition - headerHeight) {
                    filterPosition.classList.remove('fixed')
                    filterPosition.classList.remove('fixed-top')
                    filterPosition.classList.remove('bottom')
                } else if ($(window).scrollTop() > elemPosition) {
                    filterPosition.classList.remove('bottom')
                    if (document.querySelector('.scrolled--visible')) {
                        filterPosition.classList.remove('fixed-top')
                        filterPosition.classList.add('fixed')
                    } else if (!document.querySelector('.scrolled--visible')) {
                        filterPosition.classList.remove('fixed')
                        filterPosition.classList.add('fixed-top')
                    }
                }
            }
        })
    }
}

function posFixed2(filter, type, content) {
    let filterPosition = document.querySelector(filter)
    if (filterPosition) {
        let elemPosition = $(type).offset().top
        let headerHeight = $('.page-header').height()

        elemPosition = elemPosition - headerHeight + 10

        let blockHeight = $(content).height()
        let filterHeight = $('.filter').height()
        filterHeight = filterHeight + 80;
        if ($(window).scrollTop() < elemPosition) {
            filterPosition.classList.remove('fixed')
            filterPosition.classList.remove('bottom')
        } else if ($(window).scrollTop() > elemPosition + blockHeight - filterHeight) {
            filterPosition.classList.remove('fixed')
            filterPosition.classList.add('bottom')
        } else {
            filterPosition.classList.remove('bottom')
            filterPosition.classList.add('fixed')
        }
    }
}

let bistro = document.querySelector('.bistro-wrapper')
let spa = document.querySelector('.services-wrapper')
let special = document.querySelector('.news')

// bistro
if (bistro) {
    posFixed('.adaptive-menu', '.bistro-wrapper', '.bistro__content')
}

// spa
if (spa) {
    posFixed('.adaptive-menu', '.services-wrapper', '.services__content')
}

// special offers
if (special) {
    posFixed2('.filter-wrapper', '.news', '.news-content')
}

$(window).scroll(function () {
    if (bistro) {
        posFixed('.adaptive-menu', '.bistro-wrapper', '.bistro__content')
    }
    if (spa) {
        posFixed('.adaptive-menu', '.services-wrapper', '.services__content')
    }
    if (special) {
        posFixed2('.filter-wrapper', '.news', '.news-content')
    }
});

$(window).resize(function () {
    if (bistro) {
        posFixed('.adaptive-menu', '.bistro-wrapper', '.bistro__content')
    }
    if (spa) {
        posFixed('.adaptive-menu', '.services-wrapper', '.services__content')
    }
    if (special) {
        posFixed2('.filter-wrapper', '.news', '.news-content')
    }
});