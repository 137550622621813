$(document).ready(() => {
  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch

  function checkTouchDevice() {
    return "ontouchstart" in document.documentElement;
  }

  if (checkTouchDevice()) {
    $("html").addClass("touch-device");
  }

  document.addEventListener('click', function(e) {
    let target = e.target
    if(target.tagName == 'A' && target.hash == '#emptyLink') {
      e.preventDefault()
    }
  })

  require("./target-blank.js");
  require("./menu.js");
  require("./header.js");
  require("./book-form.js");
  require("./amenities.js");
  require("./video.js");
  require("./datepicker.js");
  // parallax here too
  require("./filter.js");
  // 
  require("./suites-and-rooms.js");
  require("./scrollbooster.js");
  require("./other-amenities.js");
  require("./appointment.js");
  require("./room-type-toggler.js");
  require("./adapt-filter.js");
  require("./scroll-menu.js");
  require("./adapt-scrollbar.js");
  require("./pop-up.js");
  require("./scroll2id.js");
  require("./rfp.js");
  require("./cards.js");
  require("./gallery.js");
  require("./slider.js");
  require("./art-dropdawn.js");
  require("./vimeo-play.js");
  require("./accordion.js")
});

// remove preloader
$(window).on("load", () => {
  $(".preloader").fadeOut(() => {
    require("./animations.js");
  });
});

$('a[title="download"]').attr( 'download', true );

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
      thisArg = thisArg || window;
      for (var i = 0; i < this.length; i++) {
          callback.call(thisArg, this[i], i, this);
      }
  };
}