(function () {

  let icon = function (path) {
    return wp.tplUrl + path;
  }

  $(".video").each(function () {
    const $this = $(this);
    const video = $this.find("video")[0];

    const togglePlay = () => {
      if ($this.hasClass("playing")) {
        video.pause();
        // video.controls = false;
        $this.removeClass("playing")
        this.querySelector('img').src = icon('/img/play-button-white.svg')
      } else {
        video.play();
        // video.controls = true;
        $this.addClass("playing")
        this.querySelector('img').src = icon('/img/play-pause-white.svg')
      }
    };

    if(video) {
      video.addEventListener('ended', function() {
        $this.removeClass("playing")
        $this[0].querySelector('img').src = icon('/img/play-button-white.svg')
      })
    }

    const $playBtn = $this.find(".video__play-btn");

    $playBtn.on("click", togglePlay);
  });
})();