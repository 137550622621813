;(function () {
  const $menu = $('.main-nav')
  const $hamburger = $('.hamburger-btn')
  const $closeBtn = $('.main-nav__close-btn')
  const $page = $('.page-wrapper')
  let isMenuOpened = false

  function toggleMenu() {
    $page.toggleClass('opened')
    $menu.toggleClass('opened')
    isMenuOpened = !isMenuOpened

    if (isMenuOpened) {
      bodyScrollLock.disableBodyScroll($menu[0], {
        reserveScrollBarGap: true,
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.getAttribute('body-scroll-lock-ignore') !== null) {
              return true
            }

            el = el.parentNode
          }
        },
      })
    } else {
      bodyScrollLock.clearAllBodyScrollLocks()
    }
  }

  $hamburger.on('click', toggleMenu)
  $closeBtn.on('click', toggleMenu)

  if (window.innerWidth > 576) {
    let menuWidth = document.querySelector('.main-nav')
    let initialWidth = menuWidth.offsetWidth
    let widthStep = 200

    let hide

    let setMenuWidth = function (level = 0) {
      menuWidth.style.width = widthStep * level + initialWidth + 'px'
      clearTimeout(hide)
    }

    document.addEventListener('mouseover', (e) => {
      let target = e.target
      target = target.closest('.menu-item')
      if (target) {
        let targetMenu = target.closest('ul')
        let level = 0
        while (targetMenu.classList.contains('sub-menu')) {
          level++
          targetMenu = targetMenu.closest('li')
          targetMenu = targetMenu.closest('ul')
        }
        if (target.classList.contains('menu-item-has-children')) {
          level++
        }
        setMenuWidth(level)
      }
    })

    document.addEventListener('mouseout', function (e) {
      let target = e.target
      target = target.closest('.menu-item')
      if (target) {
        hide = setTimeout(setMenuWidth, 250)
      }
    })
  }

  let addHeader = function (target) {
    let menuItem = target.parentNode
    let sub = menuItem.querySelector('.sub-menu')
    let checkSub = sub.querySelector('.temp-header')
    if (checkSub) {
      checkSub.remove()
    }
    let element = document.createElement('LI')
    let link
    if (target.hash !== '#emptyLink') {
      link = document.createElement('A')
      link.href = target
    } else {
      link = document.createElement('DIV')
    }
    link.innerHTML = target.innerHTML
    element.classList.add('temp-header')
    element.prepend(link)
    sub.prepend(element)
  }

  let removeHeader = function (menu) {
    menu.querySelector('.temp-header').remove()
  }

  document.addEventListener('click', function (e) {
    if (window.innerWidth < 577) {
      let target = e.target
      if (target.tagName == 'A') {
        if (target.parentNode.classList.contains('menu-item-has-children') && !target.closest('footer')) {
          e.preventDefault()
          let opened = document.querySelector('.menu-item-has-children > a.opened.current')
          if (opened) {
            opened.classList.remove('current')
          }
          target.classList.add('opened', 'current')
          addHeader(target)
        }
      } else {
        let opened = document.querySelector('.menu-item-has-children > a.opened.current')
        if (opened) {
          opened.classList.remove('opened', 'current')
          let openedSub = opened.closest('.sub-menu')
          if (openedSub) {
            openedSub.parentElement.querySelector('.opened').classList.add('current')
            removeHeader(opened.closest('.menu-item-has-children').querySelector('.sub-menu'))
          }
        }
      }
    }
  })
})()
