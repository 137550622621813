(function() {
  $(".book-room__select").each(function() {
    const $this = $(this);
    const $wrapper = $this.closest(".custom-dropdown-wrapper");

    $this.select2({
      minimumResultsForSearch: Infinity,
      width: "100%",
      theme: "custom",
      containerCssClass: "type-number"
    });

    $this.on("select2:opening", function() {
      $wrapper.addClass("active");
    });

    $this.on("select2:closing", function() {
      $wrapper.removeClass("active");
    });
  });

  $(".book-room__datepicker").each(function() {
    const $this = $(this);
    const $wrapper = $this.closest(".custom-dropdown-wrapper");
    let isOpened = false;

    $this.datepicker({
      language: "en",
      navTitles: {
        days: "M"
      },
      minDate: new Date(),
      onShow: function(inst, animationCompleted) {
        $wrapper.addClass("active");

        if (animationCompleted) {
          isOpened = true;
        }
      },
      onHide: function(inst, animationCompleted) {
        $wrapper.removeClass("active");

        if (animationCompleted) {
          isOpened = false;
        }
      }
    });

    $this.on("click", function() {
      if (isOpened) {
        $this.data("datepicker").hide();
      }
    });

    let dPeackers = document.querySelectorAll('.datepicker')

    dPeackers.forEach(function(dPeacker) {
      dPeacker.addEventListener("click", function(e) {
        if (isOpened && e.target.classList.contains('datepicker--cell-day')) {
          $this.data("datepicker").hide();
        }
      });
    })
  });
})();