(function () {
    let mainMenu = $('.filter--adapt');
    let testMenu = $('.adaptive-menu');
    let filtName = $('.filter__name');

    if (mainMenu.length != 0) {
        filtName.on('click', function () {
            filtName.toggleClass('active');
            mainMenu.toggleClass('active');
        });

        function closeMenu() {
            if (mainMenu.hasClass('active')) {
                filtName.toggleClass('active');
            }
            mainMenu.removeClass('active');
        }

        $(document).on('click', function (e) {
            let tempArr = e.target.parentNode.className.split(" ");
            if (tempArr[0] == 'filter-item--adapt' || tempArr[0] == '__mPS2id') {
                closeMenu()
            } else if (!testMenu.is(e.target) && testMenu.has(e.target).length === 0) {
                closeMenu()
            }
        });

        $(window).on('resize', function () {
            if (mainMenu.hasClass('filter--adapt-active') && window.innerWidth > 1366) {
                closeMenu();
            }
        })
    }
})()