let items = document.querySelectorAll('.cards__item')
let selecters = document.querySelectorAll('.replenish__selecter option')

if (items.length > 0) {
  items.forEach(function (item) {
    item.addEventListener('click', function () {

      if (!item.classList.contains('active')) {
        let active = document.querySelector('.cards__item.active')
        if (active) {
          active.classList.remove('active')
        }
        item.classList.add('active')
        let itemAttr = item.getAttribute('value')
        selecters.forEach(function(selecter) {
          let value = selecter.getAttribute('value')
          if (value == itemAttr) {
            selecter.setAttribute('selected', '')
          } else {
            selecter.removeAttribute('selected')
          }
        })
      } else {
        item.classList.remove('active')
      }

    })
  })
}

let customAmount = document.querySelector('.cards__item input')
if(customAmount) {
  customAmount.addEventListener('input', function() {
    if (this.value.length > 4) {
      this.value = this.value.slice(0,4); 
    }
    console.log(this.value)
    let parentForm = this.parentElement
    let parentItem = parentForm.parentElement
    parentItem.setAttribute('value', this.value)
    selecters[selecters.length - 1].setAttribute('value', this.value)
    // selecters[selecters.length - 1].setAttribute('selected', 'selected')
  })
}