let blocks = document.querySelectorAll('.packages__block')

let getScrollbar = function() {
  if (blocks.length > 0) {
    blocks.forEach(function(block) {
      let items = block.querySelectorAll('.packages__item')
      let item = document.querySelector('.packages__item').offsetWidth + 60
      let blockWidth = block.offsetWidth
      if (blockWidth / item < items.length) {
        block.classList.add('scroll')
      } else {
        block.classList.remove('scroll')
      }
    })
  }
}

getScrollbar()
window.addEventListener('resize', getScrollbar)

let btn = document.querySelector('#show-wedding')

let showWedding = function() {
  let item = document.querySelector('.packages__content--wedding')
  let symbol = document.createElement('i');
  if (!item.classList.contains('hide')) {
    item.classList.add('hide')
    btn.textContent = 'Show wedding packages'
    symbol.classList.add('fas', 'fa-plus')
    // for IE
    btn.insertBefore(symbol, btn.childNodes[0]);
    // 
    // btn.prepend(symbol)
  } else {
    item.classList.remove('hide')
    btn.textContent = ' Hide wedding packages'
    symbol.classList.add('fas', 'fa-minus')
    // for IE
    btn.insertBefore(symbol, btn.childNodes[0]);
    // 
    // btn.prepend(symbol)
  }
}

if (btn) {
  btn.addEventListener('click', showWedding)
}