let items = document.querySelectorAll('.art-block ul>.select-item')
let dots = document.querySelectorAll('.art-block .slick-dots>li')
let cur = document.querySelector('.art-block .select-item.current>span')
let arrows = document.querySelectorAll('.art-block .slick-arrow')

let cl = function (item) {
  document.querySelector('.select-item.active').classList.remove('active')
  let count = item.getAttribute('data-count')
  cur.textContent = item.textContent
  item.classList.add('active')
  dots[count].click()
}

items.forEach(function (item) {
  item.addEventListener('click', function () {
    cl(item)
  })
})

arrows.forEach(function (arrow) {
  arrow.addEventListener('click', function () {
    let activeSlide = document.querySelector('.slick-current')
    let pos = activeSlide.getAttribute('data-slick-index')
    cl(items[pos])
  })
})

let slider = document.querySelector('.art-slider')
let descHeight = document.querySelectorAll('.art-slider__desc')

let sliderPad = () => {
  if (slider) {
    if (window.innerWidth < 768) {
      let heightArr = []
      descHeight.forEach(function (el) {
        heightArr.push(el.offsetHeight)
      })
      slider.style.paddingBottom = Math.max.apply(null, heightArr) + 50 + 'px'
    } else {
      slider.style.paddingBottom = 0
    }
  }
}

sliderPad()
$(window).on("resize", debounce(sliderPad, 100));

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

let slides = document.querySelectorAll('.slick-slide')
let overlay = document.querySelector('.art-slider')
if (slides.length == 1) {
  overlay.classList.add('no-overlay')
}