(function () {

  let messageBox = function (position, text) {
    const message = document.createElement('p')
    message.classList.add('alert-text')
    message.textContent = text
    position.appendChild(message)
  }

  let removeMessageBox = function () {
    let alert = document.querySelector('.alert-text')
    if (alert) {
      alert.remove()
    }
  }

  let addFileButton = document.querySelector('#meeting-prepared-rfp-button')
  let addFileField = document.querySelector('#meeting-prepared-rfp')

  if (addFileField) {
    addFileField.addEventListener('change', function () {
      let parent = addFileButton.parentNode
      const message = document.createElement('p')
      message.classList.add('success-text')
      message.textContent = 'Success!'
      parent.appendChild(message)
    })
  }

  //BUTTON next
  $('.wizard:not(:first)').hide();
  $('.step:not(:first)').hide();

  $(".next").on("click", function (e) {
    if (formChecked()) {
      e.preventDefault();
      $(this).closest('.wizard').hide().next().show();
      $('body,html').animate({
        scrollTop: 0
      }, 800);
      removeMessageBox()

      phoneNumber.destroy()
      phoneNumber = window.intlTelInput(input, {
        autoPlaceholder: "aggressive",
        separateDialCode: true,
        customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
          return `${selectedCountryPlaceholder}`;
        },
      });
      elon(input)

    } else {
      removeMessageBox()
      let text = '*Select at least one item'
      let position = document.querySelector('.event-type__list')
      messageBox(position, text)
    }
  });

  let formChecked = function () {
    let formType = document.querySelector('.event-type')
    if (formType) {
      let checkBoxes = formType.querySelectorAll('input')
      for (let i = 0; i < checkBoxes.length; i++) {
        if (checkBoxes[i].checked) {
          return true
        }
      }
    } else {
      return true
    }
  }

  $(".next-step").on("click", function (e) {
    e.preventDefault();

    if (requairedFields() && emailValidPerm() && phoneValidPerm()) {
      const step = $(this).closest('.step');
      const index = $('.step').index(step);
      const $menuLinks = $('.rfp-menu__link');

      step.hide().next().show();
      $('body,html').animate({
        scrollTop: 0
      }, 800);
      $menuLinks.eq(index).addClass('rfp-menu__link--done');
      $menuLinks.eq(index + 1).addClass('rfp-menu__link--active');
      removeMessageBox()
    } else {
      $('body,html').animate({
        scrollTop: 0
      }, 800);
    }
  });

  let requairedFields = function () {
    let steps = document.querySelectorAll('.step')
    let checked = true
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].style.display !== "none") {
        let inputs = steps[i].querySelectorAll('input[required]')
        for (let y = 0; y < inputs.length; y++) {
          if (inputs[y].value === "") {
            checked = false
            removeMessageBox()
            let text = '*Required field'
            let position = inputs[y].parentNode
            messageBox(position, text)
            break
          } else {
            if (inputs[y].getAttribute('type').toLowerCase() == 'email') {
              checked = emailValidation(inputs[y])
            } else if (inputs[y].getAttribute('type').toLowerCase() == 'tel') {
              checked = phoneValidation(inputs[y])
            }
          }
        }
        let selects = steps[i].querySelectorAll('select[required]')
        for (let k = 0; k < selects.length; k++) {
          if (selects[k].value === "0") {
            checked = false
            removeMessageBox()
            let text = '*Required field'
            let position = selects[k].parentNode
            messageBox(position, text)
            break
          }
        }
      }
    }
    return checked
  }

  let emailValidPerm = function () {
    let steps = document.querySelectorAll('.step')
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].style.display !== "none") {
        let inputs = steps[i].querySelectorAll('input[type=email]')
        if (inputs.length > 0) {
          for (let y = 0; y < inputs.length; y++) {
            if (inputs[y].value != '') {
              return emailValidation(inputs[y])
            } else {
              return true
            }
          }
        }
        return true
      }
    }
  }

  let phoneValidPerm = function () {
    let steps = document.querySelectorAll('.step')
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].style.display !== "none") {
        let inputs = steps[i].querySelectorAll('input[type=tel]')
        if (inputs.length > 0) {
          for (let y = 0; y < inputs.length; y++) {
            if (inputs[y].value != '') {
              return phoneValidation(inputs[y])
            } else {
              return true
            }
          }
        }
        return true
      }
    }
  }

  let emailValidation = function (item) {
    let text = '*Please enter a valid email address'
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(item.value)) {
      removeMessageBox()
      return true
    } else {
      removeMessageBox()
      let position = item.parentNode
      messageBox(position, text)
      return false
    }
  }

  let phoneValidation = function (item) {
    let text = '*Please enter a valid phone number'
    if (item.value.match(/_/) == null) {
      removeMessageBox()
      return true
    } else {
      removeMessageBox()
      let position = item.parentNode
      messageBox(position, text)
      return false
    }
  }

  $(".prev").on("click", function (e) {
    e.preventDefault();
    $(this).closest('.wizard').hide().prev().show();
    $('body,html').animate({
      scrollTop: 0
    }, 800);
  });

  $(".prev-step").on("click", function (e) {
    e.preventDefault();

    const step = $(this).closest('.step');
    const index = $('.step').index(step);
    const $menuLinks = $('.rfp-menu__link');

    step.hide().prev().show();
    $('body,html').animate({
      scrollTop: 0
    }, 800);
    $menuLinks.eq(index).removeClass('rfp-menu__link--active');
    $menuLinks.eq(index - 1).removeClass('rfp-menu__link--done').addClass('rfp-menu__link--active');
  });

  // Link add input field
  $(".remove-field")
    .hide()
    .next()
    .hide();
  $(".add-field").on("click", function (e) {
    e.preventDefault();
    $(this)
      .stop()
      .hide()
      .siblings(".remove-field")
      .stop()
      .slideDown(200)
      .next()
      .stop()
      .slideDown(200);
  });
  $(".remove-field").on("click", function (e) {
    e.preventDefault();
    $(this).next().val('');
    $(this)
      .hide()
      .next()
      .stop()
      .slideUp(200)
      .siblings(".add-field")
      .stop()
      .slideDown(200);
  });

  // start Input Mask---------------------------------------------------------------------------------
  var input = document.querySelector("input[type=tel]:not(.js-sv-wc-payment-gateway-credit-card-form-input)");
  if (input) {
    var phoneNumber = window.intlTelInput(input, {
      autoPlaceholder: "aggressive",
      separateDialCode: true,
      customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
        return `${selectedCountryPlaceholder}`;
      },
    });

    $(input).on('close:countrydropdown', function (e) {
      elon(this)
    });

    var elon = function (e) {
      let phldr = $(e).attr('placeholder')
      var currentMask = phldr.replace(/[0-9+]/ig, '9');
      $(e).attr('placeholder', `${currentMask}`);
      $(input).inputmask({
        mask: currentMask,
        keepStatic: true
      });
    }

    elon(input)
  }
  // end Input Mask-----------------------------------------------------------------------------------

  $(".form-select").each(function () {
    const $this = $(this);
    $this.select2({
      minimumResultsForSearch: -1,
      containerCssClass: "rfp-select",
      templateSelection: function (data) {
        if (data.id === '') {
          $('#select2-rfp-form__room-rate-container').css('color', '#bbcbd5');
          return '$10000+';
        }
        $('#select2-rfp-form__room-rate-container').css('color', '#3d4543').css('font-weight', 'normal');
        return data.text;
      }
    });
    $('b[role="presentation"]').hide();
  });
})();

(function () {
  //Summary rfp inputs
  function summaryFirst(element) {
    let text
    let items = document.querySelector('.rfp__event')
    let checkedItems = items.querySelectorAll(`input[data-type=${element}]`)
    checkedItems.forEach(function (checkedItem) {
      if (checkedItem.checked) {
        let parent = checkedItem.parentNode
        let itemsText = parent.textContent
        itemsText = itemsText.trim()
        if (text == undefined) {
          text = itemsText
        } else {
          text = `${text}, ${itemsText}`
        }
      }
    })
    $('p[data-val=' + element + ']').text(text);
  };

  function summaryText(element) {
    let item = $('#' + element)
    let text
    if (item.attr('type') == "tel" && item.val() != "") {
      let num = item.val();
      let cc = document.querySelector('.iti__selected-dial-code').textContent
      text = `${cc} ${num}`;
    } else {
      text = item.val();
    }
    $('p[data-val=' + element + ']').text(text);
  };

  function summaryCheckbox(element) {
    let switcher = document.querySelector('#' + element)
    let text
    if (switcher.checked) {
      text = 'Yes'
    } else {
      text = 'No'
    }
    $('p[data-val=' + element + ']').text(text);
  };

  function summaryFinish(element) {
    const text = $('p[data-val=' + element + ']').text();
    $('input[data-ready=' + element + ']').val(text);
  };

  function summaryFinishAdds(element) {
    const text = $('input[data-val=' + element + ']').val();
    $('input[data-ready=' + element + ']').val(text);
  };

  $('.next').on('click', function () {
    let pageRfp = document.querySelector('#rfp-form').getAttribute('data-name')
    if (pageRfp == 'meeting') {
      summaryFirst('meeting');
    }
    if (pageRfp == 'wedding') {
      summaryFirst('wedding');
    }
  })

  $('.next-step').on('click', function () {
    let pageRfp = document.querySelector('#rfp-form').getAttribute('data-name')

    if (pageRfp == 'meeting') {
      summaryText('meeting-first-name');
      summaryText('meeting-last-name');
      summaryText('meeting-title');
      summaryText('meeting-company-name');
      summaryText('meeting-address');
      summaryText('meeting-city');
      summaryText('meeting-state');
      summaryText('meeting-zip');
      summaryText('meeting-email');
      summaryText('meeting-phone');
      summaryText('rfp-meeting-name');
      summaryText('meeting-purpose');
      summaryText('rfp-meeting-history');
      summaryText('meeting-guests-number');
      summaryText('meeting-guestroom-number');
      summaryText('meeting-date-start');
      summaryText('meeting-date-end');
      summaryText('meeting-requirements');
      summaryText('meeting-room-rate');
      summaryText('meeting-room-number');
      summaryText('meeting-guest-count');
      summaryText('meeting-guest-count-breakout');
      summaryText('meeting-banquet-requirements');
      summaryCheckbox('meeting-flex-date');
    } else if (pageRfp == 'wedding') {
      summaryText('wedding-first-name');
      summaryText('wedding-last-name');
      summaryText('wedding-first-name-partner');
      summaryText('wedding-last-name-partner');
      summaryText('wedding-email');
      summaryText('wedding-phone');
      summaryText('wedding-city');
      summaryText('wedding-address');
      summaryText('wedding-state');
      summaryText('wedding-zip');
      summaryText('wedding-date');
      summaryText('wedding-date-season');
      summaryText('wedding-date-year');
      summaryText('wedding-guests-number');
      summaryText('wedding-room-interest');
      summaryText('wedding-anticipated-budget');
      summaryText('wedding-rehearsal-dinner');
      summaryText('wedding-contact-method');
    } else if (pageRfp == 'golf') {
      summaryText('golf-name');
      summaryText('golf-email');
      summaryText('golf-phone');
      summaryText('golf-group');
      summaryText('golf-players-interest');
      summaryText('golf-date-start');
      summaryText('golf-date-end');
    } else if (pageRfp == 'golf-junior') {
      summaryText('golf-junior-name');
      summaryText('golf-junior-age');
      summaryText('golf-junior-phone');
      summaryText('golf-junior-clubs');
      summaryText('golf-junior-skill-level');
      summaryText('golf-junior-contact');
      summaryText('golf-junior-address');
      summaryText('golf-junior-city');
      summaryText('golf-junior-state');
      summaryText('golf-junior-zip');
      summaryFirst('golf-junior-date');
    } else if (pageRfp == 'philanthropy') {
      summaryText('philanthropy-name');
      summaryText('philanthropy-organization');
      summaryText('philanthropy-city');
      summaryText('philanthropy-state');
      summaryText('philanthropy-address');
      summaryText('philanthropy-zip');
      summaryText('philanthropy-phone');
      summaryText('philanthropy-email');
      summaryText('philanthropy-date-start');
      summaryText('philanthropy-date-end');
      summaryText('donation-type');
      summaryText('philanthropy-description');
    } else if (pageRfp == 'event') {
      summaryText('event-first-name');
      summaryText('event-last-name');
      summaryText('event-email');
      summaryText('event-phone');
      summaryText('event-city');
      summaryText('event-address');
      summaryText('event-state');
      summaryText('event-zip');
      summaryText('event-date');
      summaryText('event-guests-number');
      summaryText('event-room-interest');
      summaryText('event-anticipated-budget');
      summaryText('event-contact-method');
    } else if (pageRfp == 'dining') {
      summaryText('dining-first-name');
      summaryText('dining-last-name');
      summaryText('dining-email');
      summaryText('dining-phone');
      summaryText('dining-address');
      summaryText('dining-city');
      summaryText('dining-state');
      summaryText('dining-zip');
      summaryText('dining-date');
      summaryText('dining-guests-number');
      summaryText('dining-space');
      summaryText('dining-time');
      summaryText('dining-occasion');
    }
  });

  $('.finish').on('click', function () {
    let pageRfp = document.querySelector('#rfp-form').getAttribute('data-name')
    if (pageRfp == 'meeting') {
      summaryFinish('meeting');
      summaryFinish('meeting-first-name');
      summaryFinish('meeting-last-name');
      summaryFinish('meeting-title');
      summaryFinish('meeting-company-name');
      summaryFinish('meeting-address');
      summaryFinish('meeting-city');
      summaryFinish('meeting-state');
      summaryFinish('meeting-zip');
      summaryFinish('meeting-email');
      summaryFinish('meeting-phone');
      summaryFinish('rfp-meeting-name');
      summaryFinish('meeting-purpose');
      summaryFinish('rfp-meeting-history');
      summaryFinish('meeting-guests-number');
      summaryFinish('meeting-guestroom-number');
      summaryFinish('meeting-date-start');
      summaryFinish('meeting-date-end');
      summaryFinish('meeting-flex-date');
      summaryFinish('meeting-requirements');
      summaryFinish('meeting-room-rate');
      summaryFinish('meeting-room-number');
      summaryFinish('meeting-guest-count');
      summaryFinish('meeting-guest-count-breakout');
      summaryFinish('meeting-banquet-requirements');
      summaryFinishAdds('meeting-ask-questions');
    } else if (pageRfp == 'wedding') {
      summaryFinish('wedding');
      summaryFinish('wedding-first-name');
      summaryFinish('wedding-last-name');
      summaryFinish('wedding-first-name-partner');
      summaryFinish('wedding-last-name-partner');
      summaryFinish('wedding-email');
      summaryFinish('wedding-phone');
      summaryFinish('wedding-city');
      summaryFinish('wedding-address');
      summaryFinish('wedding-state');
      summaryFinish('wedding-zip');
      summaryFinish('wedding-date');
      summaryFinish('wedding-date-season');
      summaryFinish('wedding-date-year');
      summaryFinish('wedding-guests-number');
      summaryFinish('wedding-room-interest');
      summaryFinish('wedding-anticipated-budget');
      summaryFinish('wedding-rehearsal-dinner');
      summaryFinish('wedding-contact-method');
      summaryFinishAdds('wedding-ask-questions');
    } else if (pageRfp == 'golf') {
      summaryFinish('golf-name');
      summaryFinish('golf-email');
      summaryFinish('golf-phone');
      summaryFinish('golf-group');
      summaryFinish('golf-players-interest');
      summaryFinish('golf-date-start');
      summaryFinish('golf-date-end');
      summaryFinishAdds('golf-ask-questions');
    } else if (pageRfp == 'golf-junior') {
      summaryFinish('golf-junior-name');
      summaryFinish('golf-junior-age');
      summaryFinish('golf-junior-phone');
      summaryFinish('golf-junior-clubs');
      summaryFinish('golf-junior-skill-level');
      summaryFinish('golf-junior-contact');
      summaryFinish('golf-junior-address');
      summaryFinish('golf-junior-city');
      summaryFinish('golf-junior-state');
      summaryFinish('golf-junior-zip');
      summaryFinish('golf-junior-date');
      summaryFinishAdds('golf-junior-ask-questions');
    } else if (pageRfp == 'philanthropy') {
      summaryFinish('philanthropy-name');
      summaryFinish('philanthropy-organization');
      summaryFinish('philanthropy-city');
      summaryFinish('philanthropy-state');
      summaryFinish('philanthropy-address');
      summaryFinish('philanthropy-zip');
      summaryFinish('philanthropy-phone');
      summaryFinish('philanthropy-email');
      summaryFinish('philanthropy-date-start');
      summaryFinish('philanthropy-date-end');
      summaryFinish('donation-type');
      summaryFinish('philanthropy-description');
      summaryFinishAdds('meeting-ask-questions');
    } else if (pageRfp == 'event') {
      summaryFinish('event-first-name');
      summaryFinish('event-last-name');
      summaryFinish('event-email');
      summaryFinish('event-phone');
      summaryFinish('event-city');
      summaryFinish('event-address');
      summaryFinish('event-state');
      summaryFinish('event-zip');
      summaryFinish('event-date');
      summaryFinish('event-guests-number');
      summaryFinish('event-room-interest');
      summaryFinish('event-anticipated-budget');
      summaryFinish('event-contact-method');
      summaryFinishAdds('event-ask-questions');
    } else if (pageRfp == 'dining') {
      summaryFinish('dining-first-name');
      summaryFinish('dining-last-name');
      summaryFinish('dining-email');
      summaryFinish('dining-phone');
      summaryFinish('dining-address');
      summaryFinish('dining-city');
      summaryFinish('dining-state');
      summaryFinish('dining-zip');
      summaryFinish('dining-date');
      summaryFinish('dining-guests-number');
      summaryFinish('dining-space');
      summaryFinish('dining-time');
      summaryFinish('dining-occasion');
      summaryFinishAdds('dining-ask-questions');
    }
  });
  let numPlus = document.querySelectorAll('.nc-plus')
  let numMinus = document.querySelectorAll('.nc-minus')
  if (numPlus.length > 0) {
    numPlus.forEach(function (item) {
      let itemParent = item.parentNode
      let mainParent = itemParent.parentNode
      let targetInput = mainParent.querySelector('input')
      item.addEventListener('click', function () {
        targetInput.value = +targetInput.value + 10
        if (numMax && targetInput.value > numMax) {
          targetInput.value = numMax
        }
        setMaxNum()
      })
    })
    numMinus.forEach(function (item) {
      let itemParent = item.parentNode
      let mainParent = itemParent.parentNode
      let targetInput = mainParent.querySelector('input')
      item.addEventListener('click', function () {
        if (+targetInput.value > 10) {
          targetInput.value = +targetInput.value - 10
        } else if (+targetInput.value > 0) {
          targetInput.value = 0
        }
        setMaxNum()
      })
    })
  }
  var numMax
  let num = $('*[data-leading="leading"]')
  let numCur = $('*[data-leading="lead"]')
  let setMaxNum = function () {
    let target = $('*[data-leading="leading"]').find(':selected').data('value')
    if (target != undefined && numCur.val() > +target) {
      numCur.val(+target)
    }
  }
  setMaxNum()
  num.on('change', function (e) {
    setMaxNum()
  })
  numCur.on('input', function (e) {
    setMaxNum()
  })
})();